import { defineStore } from 'pinia'
import { ofetch } from 'ofetch'
import type { PayloadVal, TabData } from '~/models/Search'
import type { JobItem } from '~/models/Job'
import type { CompanyItem } from '~/models/Company'
import { useJobs } from '~/store/job'

interface SearchState {
  tabs: TabData[]
  countInit: number
  isLoading: boolean
  companyList: { version1: CompanyItem[]; version2: CompanyItem[] }
  jobList: JobItem[]
  searchType: string
}

export const useSearch = defineStore('search', {
  state: (): SearchState => ({
    tabs: [
      { name: 'jobs', isActive: true, size: 21, total: 0, query: { st: 'jobs', q: '', locations: null, employment: null, work_type: null, date_posted: null, sort: 'date', page: 1 } },
      { name: 'companies', isActive: false, size: 21, total: 0, query: { st: 'companies', q: '', location: null, industry: null, sort: 'date', page: 1 } },
    ] as TabData[],
    countInit: 0,
    isLoading: false,
    companyList: { version1: [], version2: [] },
    jobList: [],
    searchType: null,
  }),

  actions: {
    resetSearch() {
      this.tabs = [
        { name: 'jobs', isActive: true, size: 21, total: 0, query: { st: 'jobs', q: '', locations: null, employment: null, work_type: null, date_posted: null, sort: 'date', page: 1 } },
        { name: 'companies', isActive: false, size: 21, total: 0, query: { st: 'companies', q: '', location: null, industry: null, sort: 'date', page: 1 } },
      ]
      this.companyList = { version1: [], version2: [] }
      this.jobList = []
    },
    getDateRange(option: string) {
      const now = new Date()
      let days

      switch (option) {
        case '24hours': days = 1; break
        case '7days': days = 7; break
        case '2weeks': days = 14; break
        case '1month': days = 30; break
        default: days = 0
      }

      const date_published_from = new Date(now.getTime() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
      const date_published_to = now.toISOString().split('T')[0]

      return { date_published_from, date_published_to }
    },
    async setJobList(countryVal: string, payloadVal: PayloadVal, changeQuery: boolean, longtailVal: string) {
      const router = useRouter()
      const route = useRoute()
      const config = useRuntimeConfig()
      const dateRange = this.getDateRange(payloadVal.date)

      if (changeQuery && process.client)
        this.isLoading = true

      await Promise.all([
        useAsyncData('init_search_job', () =>
          ofetch(`${config.public.SEARCHAPI_BASE_URL}/jobs/search`, {
            method: 'POST',
            body: {
              country: countryVal,
              cities: (isEmpty(payloadVal.locations) || payloadVal.locations[0] === 'all') ? '' : useTextToParam(payloadVal.locations, true).split(','),
              types: isEmpty(payloadVal.type) ? ['full_time', 'part_time', 'freelance', 'internship'] : payloadVal.type,
              remote: payloadVal.remote,
              date_published_from: payloadVal.date ? dateRange.date_published_from : null,
              date_published_to: payloadVal.date ? dateRange.date_published_to : null,
              term: payloadVal.keyword,
              from: (payloadVal.from - 1) * payloadVal.size,
              size: payloadVal.size,
              sort: payloadVal.sort,
              type: 'sematic_search_with_similar_titles_search',
            },
            // async onRequest({ request, options }) {
            //   console.debug('Request URL:', request) // Logs the URL
            //   console.debug('Request Options:', options) // Logs the full options object
            // },
            // async onRequestError({ error }) {
            //   console.debug('Request Error:', error)
            //   throw error
            // },
            // async onResponseError({ request, response }) {
            //   console.debug('Response Error:', response.status, response.body)
            //   console.error('[fetch response error]', request, response.status, response.body)
            // },
          }),
        ),
      ]).then(async (values) => {
        const resp = values[0]?.data?.value as { total: number; hits?: JobItem[] }
        this.jobList = resp.hits
        this.isLoading = false
        this.tabs[0] = {
          name: 'jobs',
          isActive: !!changeQuery,
          total: resp?.total | 0,
          size: payloadVal.size,
          query: {
            st: 'jobs',
            q: payloadVal.keyword,
            locations: payloadVal.locations.length > 0 ? payloadVal.locations.join(',') : 'all',
            employment: payloadVal.type.length > 0 ? payloadVal.type.join(',') : 'all',
            date_posted: !isEmpty(payloadVal.date) ? payloadVal.date : 'all',
            work_type: !isEmpty(payloadVal.remote) ? payloadVal.remote : 'all',
            sort: payloadVal.sort,
            page: payloadVal.from,
          },
        }

        if (changeQuery) {
          this.tabs[1].isActive = false
          this.tabs[0].isActive = true
          this.isLoading = false
          if (longtailVal !== null && !route.fullPath.includes('locations') && this.tabs[0].query?.locations === 'all' && this.tabs[0].query?.employment === 'all' && this.tabs[0].query?.date_posted === 'all' && this.tabs[0].query?.work_type === 'all')
            navigateTo(`${longtailVal}${this.tabs[0].query?.sort === 'date' || this.tabs[0].query?.page !== 1 ? '?' : ''}${this.tabs[0].query?.sort === 'date' ? 'sort=date' : ''}${this.tabs[0].query?.sort === 'date' && this.tabs[0].query?.page !== 1 ? '&' : ''}${this.tabs[0].query?.page !== 1 ? `page=${this.tabs[0].query?.page}` : ''}`)
          else
            router.replace({ path: '/search', query: this.tabs[0].query })
        }
        else { this.countInit++ }
      })
    },
    async setCompanyList(countryVal: string, payloadVal: PayloadVal, changeQuery: boolean) {
      const router = useRouter()
      const gicsList = useGetGICS()
      const config = useRuntimeConfig()

      if (changeQuery && process.client)
        this.isLoading = true

      await Promise.all([
        useAsyncData('init_search_company', () =>
          ofetch(`${config.public.SEARCHAPI_BASE_URL}/v2/companies/search`, {
            method: 'POST',
            body: {
              country: countryVal,
              city: payloadVal.location,
              industry: !isEmpty(payloadVal.industry) ? gicsList.find(obj => obj.value === payloadVal.industry)?.name.replaceAll('and', '&') : '',
              term: payloadVal.keyword,
              order: payloadVal.sort,
              from: (payloadVal.from - 1) * payloadVal.size,
              size: payloadVal.size,
            },
            // async onRequestError({ error }) {
            //   console.debug('Request Error:', error)
            //   throw error
            // },
            // async onResponseError({ request, response }) {
            //   console.debug('Response Error:', response.status, response.body)
            //   console.error('[fetch response error]', request, response.status, response.body)
            // },
          }),
        ),
      ]).then(async (values) => {
        const resp = values[0]?.data?.value as { total: number; hits?: CompanyItem[] }
        this.companyList = !isEmpty(resp?.hits) ? { version1: resp?.hits.filter(company => company.employerVersion !== 2), version2: resp?.hits.filter(company => company.employerVersion === 2) } : { version1: [], version2: [] }
        this.isLoading = false
        this.tabs[1] = {
          name: 'companies',
          isActive: false,
          total: resp?.total | 0,
          size: payloadVal.size,
          query: {
            st: 'companies',
            q: payloadVal.keyword,
            location: !isEmpty(payloadVal.location) ? payloadVal.location : 'all',
            industry: !isEmpty(payloadVal.industry) ? payloadVal.industry : 'all',
            sort: payloadVal.sort,
            page: payloadVal.from,
          },
        }
        if (changeQuery) {
          this.tabs[0].isActive = false
          this.tabs[1].isActive = true
          this.isLoading = false
          router.replace({ path: '/search', query: this.tabs[1].query })
        }

        else { this.countInit++ }
      })
    },
    async setActive(payloadVal: PayloadVal, changeQuery: boolean, setTabActive: string, longtailVal: string) {
      const router = useRouter()
      const config = useRuntimeConfig()
      const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })
      this.isLoading = true
      this.countInit = 0

      await this.setJobList(region.value, payloadVal, false, longtailVal)
      await this.setCompanyList(region.value, payloadVal, false)
      if (this.countInit === 2) {
        if (!changeQuery && setTabActive) {
          // if changeQuery value is false (false if its coming from longtail) & setTabActive have value
          this.tabs[0].isActive = setTabActive === 'jobs'
          this.tabs[1].isActive = setTabActive !== 'jobs'
        }
        else if (changeQuery) {
          if (setTabActive) {
            this.tabs[0].isActive = setTabActive === 'jobs'
            this.tabs[1].isActive = setTabActive !== 'jobs'
            router.replace({ path: '/search', query: this.tabs[setTabActive === 'jobs' ? 0 : 1].query })
          }
          else {
            if ((this.tabs[1].total > this.tabs[0].total)) {
              this.tabs[1].isActive = true
              this.tabs[0].isActive = false
              router.replace({ path: '/search', query: this.tabs[1].query })
            }
            else {
              this.tabs[0].isActive = true
              this.tabs[1].isActive = false
              router.replace({ path: '/search', query: this.tabs[0].query })
            }
          }
        }
        this.isLoading = false
      }
      else {
        this.isLoading = false
        this.countInit = 0
      }
    },
  },

  persist: {
    key: 'search',
    storage: process.client ? window.localStorage : null,
  },
})
