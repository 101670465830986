import GICS from 'gics'

export function useGetGICS() {
  const allIndustry = ['101010', '101020', '151010', '151020', '151030', '151040', '151050', '201010', '201020', '201030', '201040', '201050', '201060', '201070', '202010', '202020', '203010', '203020', '203030', '203040', '203050', '251010', '251020', '252010', '252020', '252030', '253010', '253020', '255010', '255030', '255040', '301010', '302010', '302020', '302030', '303010', '303020', '351010', '351020', '351030', '352010', '352020', '352030', '401010', '402010', '402020', '402030', '402040', '403010', '451020', '451030', '452010', '452020', '452030', '453010', '501010', '501020', '502010', '502020', '502030', '551010', '551020', '551030', '551040', '551050', '601010', '601025', '601030', '601040', '601050', '601060', '601070', '601080', '602010']
  const gicsIndustry = ref([])
  allIndustry.forEach((idGroup) => {
    const idGroupData = new GICS(idGroup, '20230318')
    gicsIndustry.value.push({ id: idGroup, name: idGroupData.level(3).name.replaceAll('&', 'and'), value: idGroupData.level(3).name.toLowerCase().replaceAll('&', 'and').replace(/[^\w\d\s-]/g, '').replaceAll('-', '_').replaceAll(' ', '_') })
  })

  return gicsIndustry.value
}
