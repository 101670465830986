import locales from '~/assets/data/locales.json'

export default defineNuxtRouteMiddleware (async ({ path, fullPath, query, hash }) => {
  const config = useRuntimeConfig()
  const diffHrefLang = (path === '/terms-of-service' || path === '/privacy-policy' || path === '/faq' || path === '/contact' || path === '/career-guide' || path.split('/')[1] === 'jobs')
  const alternateLinks = locales.map((locale: any) => ({ rel: 'alternate', key: locale.lang, hreflang: diffHrefLang ? locale.hrefLang : locale.lang, href: diffHrefLang ? useSetSubdomain(locale.region) + fullPath : useSetSubdomain(locale.region) }))
  useHead({
    link: [
      { rel: 'alternate', key: 'en', hreflang: diffHrefLang ? 'x-default' : 'en', href: diffHrefLang ? useSetSubdomain('us') + fullPath : config.public.SITEMAP_HOST },
      ...alternateLinks,
    ],
  })

  const mapURLs = [
    { path: '/ms-my', redirect: '/my' },
    { path: '/browsejobs/t/virtual-assistant-jobs', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/c/indonesia', redirect: '/indonesia-jobs' },
    { path: '/browsejobs/c/jobs-in-Malaysia', redirect: '/malaysia-jobs' },
    { path: '/browsejobs/c/philippines', redirect: '/philippines-jobs' },
    { path: '/browsejobs/t/0-jobs-in-Indonesia/bekasi', redirect: '/indonesia-jobs' },
    { path: '/browsejobs/c/jobs-in-Philippines', redirect: '/philippines-jobs' },
    { path: '/browsejobs/c/jobs-in-indonesia', redirect: '/indonesia-jobs' },
    { path: '/browsejobs/c/malaysia', redirect: '/malaysia-jobs' },
    { path: '/browsejobs/t/mahsyar-kasih-sdn-bhd-jobs', redirect: '/companies/mahsyar-kasih-sdn-bhd-jobs' },
    { path: '/browsejobs/t/clinic-assistant-jobs-in-Malaysia/Ipoh', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/c/Malaysia/jobs-in-Ipoh', redirect: '/malaysia-jobs' },
    { path: '/browsejobs/t/office-manager-jobs-in-Malaysia/Kuala', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/t/country-manager-jobs-in-Philippines/Manila', redirect: '/human-resource-jobs' },
    { path: '/browsejobs/t/data-entry-jobs-in-Indonesia/Bekasi', redirect: '/it-engineering-jobs' },
    { path: '/browsejobs/t/graphic-designer-jobs', redirect: '/marketing-jobs' },
    { path: '/browsejobs/t/ux-researcher-jobs-in-Indonesia/Jakarta', redirect: '/it-engineering-jobs' },
    { path: '/browsejobs/t/pet-care-jobs-in-Indonesia', redirect: '/indonesia-jobs/' },
    { path: '/my/browsejobs/t/administration-jobs-in-Indonesia', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/c/Philippines/jobs-in-Manila', redirect: '/philippines-jobs' },
    { path: '/browsejobs/t/channel-marketing-manager-jobs-in-Indonesia/Jakarta', redirect: '/marketing-jobs' },
    { path: '/browsejobs/t/clerk-jobs-in-Malaysia/Ipoh', redirect: '/it-engineering-jobs' },
    { path: '/browsejobs/t/economics-teacher-jobs-in-Indonesia/Jakarta', redirect: '/indonesia-jobs/' },
    { path: '/browsejobs/t/policy-intern-jobs', redirect: '/indonesia-jobs/' },
    { path: '/browsejobs/t/service-team-leader-jobs-in-Malaysia/Kuala-Lumpur', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/t/shop-assistant-jobs-in-Malaysia/Kuantan', redirect: '/customer-service-jobs' },
    { path: '/browsejobs/t/tailor-jobs-in-Malaysia/Kuala-Lumpur', redirect: '/malaysia-jobs' },
    { path: '/browsejobs/t/data-entry-jobs', redirect: '/it-engineering-jobs' },
    { path: '/browsejobs/t/game-master-jobs-in-Malaysia', redirect: '/it-engineering-jobs' },
    { path: '/browsejobs/t/hair-trainer-jobs', redirect: '/continue' },
    { path: '/browsejobs/t/music-teacher-jobs-in-Indonesia/Jakarta', redirect: '/indonesia-jobs/' },
    { path: '/browsejobs/t/sushi-chef-jobs-in-Turkey/Istanbul', redirect: '/continue' },
    { path: '/browsejobs', redirect: '/full-time-jobs' },
    { path: '*/page/remote-jobs-available', redirect: '/remote-jobs' },
    { path: '/page/remote-jobs-available', redirect: '/remote-jobs' },
    { path: '/page/resume-checker-result', redirect: '/' },
    { path: '/page/profilerank', redirect: '/' },
    { path: '/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/gb/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/sg/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/my/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/id/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/ph/job-seeker-resource', redirect: '/', pathOnly: true, type: 302 },
    { path: '/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },
    { path: '/gb/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },
    { path: '/sg/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },
    { path: '/my/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },
    { path: '/id/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },
    { path: '/ph/job-seeker-insight', redirect: '/', pathOnly: true, type: 302 },

    { path: '/full-time-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/part-time-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/remote-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/freelance-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/marketing-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/it-engineering-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/sales-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/customer-service-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/human-resource-jobs', redirect: '/', pathOnly: true, type: 302 },
    { path: '/accounting-jobs', redirect: '/', pathOnly: true, type: 302 },

    { path: '/united-states-jobs', redirect: useSetSubdomain('us'), pathOnly: true, type: 302 },
    { path: '/singapore-jobs', redirect: `${useSetSubdomain('sg')}/jobs`, pathOnly: true, type: 302 },
    { path: '/philippines-jobs', redirect: `${useSetSubdomain('ph')}/jobs`, pathOnly: true, type: 302 },
    { path: '/malaysia-jobs', redirect: `${useSetSubdomain('my')}/jobs`, pathOnly: true, type: 302 },
    { path: '/indonesia-jobs', redirect: `${useSetSubdomain('id')}/jobs`, pathOnly: true, type: 302 },

    { path: '/united-states-companies', redirect: useSetSubdomain('us'), pathOnly: true, type: 302 },
    { path: '/singapore-companies', redirect: `${useSetSubdomain('sg')}/companies/all`, pathOnly: true, type: 302 },
    { path: '/philippines-companies', redirect: `${useSetSubdomain('ph')}/companies/all`, pathOnly: true, type: 302 },
    { path: '/malaysia-companies', redirect: `${useSetSubdomain('my')}/companies/all`, pathOnly: true, type: 302 },
    { path: '/indonesia-companies', redirect: `${useSetSubdomain('id')}/companies/all`, pathOnly: true, type: 302 },
    { path: '/companies/search', redirect: useSetSubdomain('us'), pathOnly: true, type: 302 },
    { path: '/register', redirect: '/continue', pathOnly: true, type: 301 },
    { path: '/login', redirect: '/continue', pathOnly: true, type: 301 },
  ]

  const matchedURL: { path: string; redirect: string; pathOnly?: boolean; type?: number } = mapURLs.find(url => url.pathOnly ? path.startsWith(encodeURI(url.path)) : path === encodeURI(url.path))
  if (matchedURL) {
    const nextPath = matchedURL.redirect
    const nextRoute = { path: nextPath, query, hash }
    const redirectCode = matchedURL?.type || 301

    return navigateTo(nextRoute, { redirectCode, external: true })
  }
  else {
    // this should redirect all /locale/xxx to its perspective subdomain
    const urlLocale = fullPath.split('/')
    const currentLocale = urlLocale[1] === 'gb' ? 'uk' : urlLocale[1]
    if (locales.some(item => item.region === currentLocale)) {
      const newUrl = urlLocale.length > 2 ? fullPath.replace(/^\/[^/]+(\/.*)/, '$1') : ''
      return navigateTo(`${useSetSubdomain(currentLocale)}${newUrl.endsWith('/') ? newUrl.slice(0, -1) : newUrl}`, { external: true })
    }
    else {
      if (fullPath !== '/' && fullPath.endsWith('/'))
        return navigateTo(fullPath.slice(0, -1), { redirectCode: 301 })
    }
  }
})
