export function useGetSubdomain() {
  const config = useRuntimeConfig()
  const subdomain = useRequestURL().href.replace('https://', '').replace('http://', '').split('.')
  const region = ref()

  if (config.public.ENV === 'development' || config.public.ENV === 'staging')
    region.value = subdomain[0].split('-')[1]
  else
    region.value = subdomain.length === 2 ? '' : subdomain[0]

  return region.value
}
